<template>
  <v-container>
    <v-card elevation="0" class="ma-0 pa-0">
      <v-card-title> Alta de Localidades </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="submit" autocomplete="off">
          <v-card-text>
            <validation-provider
              v-slot="{ errors }"
              name="Pais"
              :rules="{
                required: true
              }"
            >
              <v-select
                :items="paises"
                label="Pais"
                v-model="pais"
                :error-messages="errors"
                clearable
                hide-selected
                required
                item-text="txtDesc"
                item-value="id"
                autocomplete="off"
                @change="if(pais){ cargaProvincias() } else{ provincia = null; provincias = []; }"
                ref="cmbPaises"
              ></v-select>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Provincias"
              :rules="{
                required: true
              }"
            >
              <v-select
                :items="provincias"
                label="Provincias"
                v-model="provincia"
                :error-messages="errors"
                clearable
                hide-selected
                required
                item-text="txtDesc"
                item-value="id"
                autocomplete="off"
                ref="cmbProvincias"
              ></v-select>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Localidad"
              rules="required|min:4|max:50"
            >
              <v-text-field
                v-model="localidad"
                :counter="50"
                :error-messages="errors"
                label="Localidad"
                clearable
                required
                autocomplete="off"
                ref="localidad"
              ></v-text-field>
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn
                  class="mr-4"
                  type="submit"
                  block
                  plain
                  :disabled="invalid"
                >
                  Guardar
                </v-btn>
              </v-col>
              <v-col>
                <v-btn block plain @click="clear"> Limpiar </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
import { required, max, min, oneOf } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import axios from "axios";
import { mapState, mapActions } from "vuex";

setInteractionMode("aggressive");

extend("required", {
  ...required,
  message: "{_field_} no puede quedar vacio",
});

extend("max", {
  ...max,
  message: "{_field_} no puede tener mas de {length} caractéres",
});

extend("min", {
  ...min,
  message: "{_field_} no puede tener menos de {length} caractéres",
});

extend("oneOf", {
  ...oneOf,
  message: "Debe seleccionar un item del combo.",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    paises: [],
    provincias: [],
    pais: null,
    provincia: null,
    localidad: ''
  }),

  computed: {
    ...mapState(["usuario"]),
  },

  created() {
    this.cargaPaises();
  },

  methods: {
    ...mapActions(["setAlerta"]),

  async cargaPaises () {
      var response = await axios({
        url: process.env.VUE_APP_API + "parametrizacion/Paises",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + this.usuario.token,
        },
      });

      this.paises = response.data.data;
    },

    async cargaProvincias () {
      var response = await axios({
        url: process.env.VUE_APP_API + "parametrizacion/Provincias/" + this.pais,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + this.usuario.token,
        },
      });

      this.provincias = response.data.data;
    },

    async submit() {
      try {
        this.$refs.observer.validate();
        var response = await axios({
          url: process.env.VUE_APP_API + "parametrizacion/localidad",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + this.usuario.token,
          },
          data: { IdPais: this.pais, IdProvincia: this.provincia, txtDesc: this.localidad },
        });
        if (response.data.estado == "OK") {
          this.setAlerta({
            snackbar_color: "success",
            text_snackbar:
              "Se agrego " + response.data.data.txtDesc.toUpperCase() + " como localidad.",
            snackbar: true,
          });
          this.clear();
        } else {
          this.setAlerta({
            snackbar_color: "warning",
            text_snackbar: response.data.data.error,
            snackbar: true,
          });
        }
      } catch (error) {
        this.setAlerta({
          snackbar_color: "danger",
          text_snackbar: error,
          snackbar: true,
        });
      }
    },
    clear() {
      this.localidad =  '';
      setTimeout(() => {
        this.$refs.observer.reset();
      }, 16);
      this.$refs.localidad.focus();
    },
  },
};
</script>