var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"ma-0 pa-0",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Alta de Localidades ")]),_c('v-divider',{staticClass:"mx-4"}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"Pais","rules":{
              required: true
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{ref:"cmbPaises",attrs:{"items":_vm.paises,"label":"Pais","error-messages":errors,"clearable":"","hide-selected":"","required":"","item-text":"txtDesc","item-value":"id","autocomplete":"off"},on:{"change":function($event){if(_vm.pais){ _vm.cargaProvincias() } else{ _vm.provincia = null; _vm.provincias = []; }}},model:{value:(_vm.pais),callback:function ($$v) {_vm.pais=$$v},expression:"pais"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Provincias","rules":{
              required: true
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{ref:"cmbProvincias",attrs:{"items":_vm.provincias,"label":"Provincias","error-messages":errors,"clearable":"","hide-selected":"","required":"","item-text":"txtDesc","item-value":"id","autocomplete":"off"},model:{value:(_vm.provincia),callback:function ($$v) {_vm.provincia=$$v},expression:"provincia"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Localidad","rules":"required|min:4|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{ref:"localidad",attrs:{"counter":50,"error-messages":errors,"label":"Localidad","clearable":"","required":"","autocomplete":"off"},model:{value:(_vm.localidad),callback:function ($$v) {_vm.localidad=$$v},expression:"localidad"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","block":"","plain":"","disabled":invalid}},[_vm._v(" Guardar ")])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","plain":""},on:{"click":_vm.clear}},[_vm._v(" Limpiar ")])],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }